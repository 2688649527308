'use client'
import Script from 'next/script'
import { GA_MEASUREMENT_ID } from '@/client/google-analytics'

const GoogleAnalytics = () => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${GA_MEASUREMENT_ID}', {
                      page_path: window.location.pathname
                      });
                    `,
        }}
      />
    </>
  )
}

export default GoogleAnalytics
