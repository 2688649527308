'use client'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { getSession, SessionProvider } from 'next-auth/react'
import { Provider as JoTaiProvider } from 'jotai'
import { ReactNode, useEffect } from 'react'

export default function TopLevelProviders({
  children,
}: {
  children: ReactNode
}) {
  useEffect(() => {
    // @ts-ignore
    if (!window.DD_RUM || !window.DD_RUM.getInternalContext()) {
      datadogRum.init({
        applicationId: '0968d5da-53e9-4691-aedf-2fc907468c4a',
        clientToken: 'pubca9cf970e7eec36c1bc90ef70bbaf998',
        site: 'datadoghq.com',
        service: 'personas-ui',
        env: process.env.NEXT_PUBLIC_DD_ENV,
        version: process.env.NEXT_PUBLIC_DD_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
        allowedTracingUrls: [
          {
            match: /https:\/\/.*\.shiftplatform\.ai/,
            propagatorTypes: ['datadog', 'tracecontext'],
          },
          {
            match: process.env.NEXT_PUBLIC_WEBSOCKET_URL!,
            propagatorTypes: ['datadog', 'tracecontext'],
          },
        ],
        beforeSend: (event, context) => {
          if (event.view?.url) {
            const url = new URL(event.view.url)
            event.context!.shift_personas = [
              {
                regex: /\/app\/projects\/([^/]{32,36}).*/,
                name: 'project_id',
              },
              {
                regex:
                  /\/app\/projects\/[^/]+\/customer-segments\/([^/]{32,36}).*/,
                name: 'customer_segment_id',
              },
              {
                regex:
                  /\/app\/projects\/[^/]+\/customer-segments\/create\/([^/]{32,36}).*/,
                name: 'persona_generation_id',
              },
            ].reduce((obj, config) => {
              const match = config.regex.exec(url.pathname)
              if (match) {
                return Object.assign(obj, { [config.name]: match[1] })
              }
              return obj
            }, {})
          }
          return true
        },
      })
      datadogRum.startSessionReplayRecording()

      datadogLogs.init({
        clientToken: 'pubca9cf970e7eec36c1bc90ef70bbaf998',
        site: 'datadoghq.com',
        service: 'personas-ui',
        env: process.env.NEXT_PUBLIC_DD_ENV,
        version: process.env.NEXT_PUBLIC_DD_VERSION,
        sessionSampleRate: 100,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
      })
    }

    getSession().then((session) =>
      datadogRum.setUser(
        Object.assign(
          { id: 'unauthenticated' },
          session
            ? {
                id: session.user.id,
                name: session.user.name || '',
                email: session.user.email!,
                state: session.user.state,
                betaWaitList: session.user.betaWaitList,
                firstLogin: session.user.firstLogin,
              }
            : {},
        ),
      ),
    )
  }, [])

  // const launchDarklyOptions: LDOptions = {
  //   inspectors: [
  //     {
  //       type: 'flag-used',
  //       name: 'dd-inspector',
  //       method: (key: string, detail: LDEvaluationDetail) => {
  //         datadogRum.addFeatureFlagEvaluation(key, detail.value)
  //       },
  //     },
  //   ],
  // }

  return (
    <SessionProvider>
      {/*<LDProvider*/}
      {/*  options={launchDarklyOptions}*/}
      {/*  clientSideID={process.env.NEXT_PUBLIC_LAUNCH_DARKLY_KEY || ''}*/}
      {/*>*/}
      <JoTaiProvider>{children}</JoTaiProvider>
      {/*</LDProvider>*/}
    </SessionProvider>
  )
}
