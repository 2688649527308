import { usePathname } from 'next/navigation'
import { useEffect, useRef } from 'react'

export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID

export const pageview = (url: URL) => {
  window.gtag('config', GA_MEASUREMENT_ID as string, {
    page_location: url,
  })
}

export const event = (
  action: Gtag.EventNames,
  { event_category, event_label, value }: Gtag.EventParams,
) => {
  window.gtag('event', action, {
    event_category,
    event_label,
    value,
  })
}

// This is not necessary as Google Analytics 4 handles this automatically
// Leaving it for reference in case things change
export const useGtag = () => {
  const pathname = usePathname()

  const savedPathNameRef = useRef(pathname)

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return

    const handleRouteChange = (url: URL) => {
      pageview(url)
    }

    if (savedPathNameRef.current !== pathname) {
      handleRouteChange(new URL(pathname, window.location.origin))
      savedPathNameRef.current = pathname
    }
  }, [pathname])
}
