'use client'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { ColorModeContext } from '@/app/_context/color-mode'
import { useServerInsertedHTML } from 'next/navigation'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import React, { useContext } from 'react'
import { Mulish } from 'next/font/google'

const mulish = Mulish({
  weight: ['300', '400', '500', '700', '800'],
  subsets: ['latin'],
})

const rootElement =
  typeof window === 'object' ? document.getElementById('__next') : null

export default function ThemeRegistry(props: any) {
  const { options, children } = props

  const [{ cache, flush }] = React.useState(() => {
    const cache = createCache(options)
    cache.compat = true
    const prevInsert = cache.insert
    let inserted: string[] = []
    cache.insert = (...args) => {
      const serialized = args[1]
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name)
      }
      return prevInsert(...args)
    }
    const flush = () => {
      const prevInserted = inserted
      inserted = []
      return prevInserted
    }
    return { cache, flush }
  })

  const { mode } = useContext(ColorModeContext)

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: { main: '#22E77F' },
          secondary: { main: '#C8EDF9' },
          error: { main: '#D32F2F' },
          warning: { main: '#ED6C02' },
          info: { main: '#0288D1' },
          success: { main: '#2E7D32' },
          background: {
            default: mode === 'light' ? '#F8F8F8' : '#141A1A',
            paper: mode === 'light' ? '#FFFFFF' : '#232929',
          },
          mode,
        },
        shadows: [
          'none',
          '0px 2px 1px -1px rgba(0,0,0,0.16),0px 1px 1px 0px rgba(0,0,0,0.08),0px 1px 3px 0px rgba(0,0,0,0.06)',
          '0px 3px 1px -2px rgba(0,0,0,0.16),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.06)',
          '0px 3px 3px -2px rgba(0,0,0,0.16),0px 3px 4px 0px rgba(0,0,0,0.08),0px 1px 8px 0px rgba(0,0,0,0.06)',
          '0px 2px 4px -1px rgba(0,0,0,0.16),0px 4px 5px 0px rgba(0,0,0,0.08),0px 1px 10px 0px rgba(0,0,0,0.06)',
          '0px 3px 5px -1px rgba(0,0,0,0.16),0px 5px 8px 0px rgba(0,0,0,0.08),0px 1px 14px 0px rgba(0,0,0,0.06)',
          '0px 3px 5px -1px rgba(0,0,0,0.16),0px 6px 10px 0px rgba(0,0,0,0.08),0px 1px 18px 0px rgba(0,0,0,0.06)',
          '0px 4px 5px -2px rgba(0,0,0,0.16),0px 7px 10px 1px rgba(0,0,0,0.08),0px 2px 16px 1px rgba(0,0,0,0.06)',
          '0px 5px 5px -3px rgba(0,0,0,0.16),0px 8px 10px 1px rgba(0,0,0,0.08),0px 3px 14px 2px rgba(0,0,0,0.06)',
          '0px 5px 6px -3px rgba(0,0,0,0.16),0px 9px 12px 1px rgba(0,0,0,0.08),0px 3px 16px 2px rgba(0,0,0,0.06)',
          '0px 6px 6px -3px rgba(0,0,0,0.16),0px 10px 14px 1px rgba(0,0,0,0.08),0px 4px 18px 3px rgba(0,0,0,0.06)',
          '0px 6px 7px -4px rgba(0,0,0,0.16),0px 11px 15px 1px rgba(0,0,0,0.08),0px 4px 20px 3px rgba(0,0,0,0.06)',
          '0px 7px 8px -4px rgba(0,0,0,0.16),0px 12px 17px 2px rgba(0,0,0,0.08),0px 5px 22px 4px rgba(0,0,0,0.06)',
          '0px 7px 8px -4px rgba(0,0,0,0.16),0px 13px 19px 2px rgba(0,0,0,0.08),0px 5px 24px 4px rgba(0,0,0,0.06)',
          '0px 7px 9px -4px rgba(0,0,0,0.16),0px 14px 21px 2px rgba(0,0,0,0.08),0px 5px 26px 4px rgba(0,0,0,0.06)',
          '0px 8px 9px -5px rgba(0,0,0,0.16),0px 15px 22px 2px rgba(0,0,0,0.08),0px 6px 28px 5px rgba(0,0,0,0.06)',
          '0px 8px 10px -5px rgba(0,0,0,0.16),0px 16px 24px 2px rgba(0,0,0,0.08),0px 6px 30px 5px rgba(0,0,0,0.06)',
          '0px 8px 11px -5px rgba(0,0,0,0.16),0px 17px 26px 2px rgba(0,0,0,0.08),0px 6px 32px 5px rgba(0,0,0,0.06)',
          '0px 9px 11px -5px rgba(0,0,0,0.16),0px 18px 28px 2px rgba(0,0,0,0.08),0px 7px 34px 6px rgba(0,0,0,0.06)',
          '0px 9px 12px -6px rgba(0,0,0,0.16),0px 19px 29px 2px rgba(0,0,0,0.08),0px 7px 36px 6px rgba(0,0,0,0.06)',
          '0px 10px 13px -6px rgba(0,0,0,0.16),0px 20px 31px 3px rgba(0,0,0,0.08),0px 8px 38px 7px rgba(0,0,0,0.06)',
          '0px 10px 13px -6px rgba(0,0,0,0.16),0px 21px 33px 3px rgba(0,0,0,0.08),0px 8px 40px 7px rgba(0,0,0,0.06)',
          '0px 10px 14px -6px rgba(0,0,0,0.16),0px 22px 35px 3px rgba(0,0,0,0.08),0px 8px 42px 7px rgba(0,0,0,0.06)',
          '0px 11px 14px -7px rgba(0,0,0,0.16),0px 23px 36px 3px rgba(0,0,0,0.08),0px 9px 44px 8px rgba(0,0,0,0.06)',
          '0px 11px 15px -7px rgba(0,0,0,0.16),0px 24px 38px 3px rgba(0,0,0,0.08),0px 9px 46px 8px rgba(0,0,0,0.06)',
        ],
        typography: {
          ...mulish.style,
          fontSize: 16,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
          fontWeightBold: 700,
          h1: {
            color: '#141A1A',
            fontSize: 24,
            fontWeight: 700,
            lineHeight: 1.334,
          },
          h2: {
            color: '#000000',
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 1.2,
            letterSpacing: '0.15px',
          },
          h3: {
            color: '#00000099',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1.4,
            letterSpacing: '0.15px',
          },
          h4: {
            fontSize: 30,
            fontWeight: 500,
            lineHeight: 1.235,
            letterSpacing: '0.25px',
          },
          h5: {
            fontSize: 24,
            fontWeight: 700,
            lineHeight: 1.334,
          },
          body1: {
            color: '#00000099',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1.4,
            letterSpacing: '0.15px',
          },
          body2: {
            color: '#ffffff',
            fontSize: 16,
          },
        },
      }),
    [mode],
  )

  useServerInsertedHTML(() => {
    const names = flush()
    if (names.length === 0) {
      return null
    }
    let styles = ''
    for (const name of names) {
      styles += cache.inserted[name]
    }
    return (
      <style
        key={cache.key}
        data-emotion={`${cache.key} ${names.join(' ')}`}
        dangerouslySetInnerHTML={{
          __html: options.prepend ? `@layer emotion {${styles}}` : styles,
        }}
      />
    )
  })

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  )
}
