'use client'
import { createContext, useState, useEffect, ReactNode } from 'react'

interface ColorModeContextType {
  mode: 'light' | 'dark'
  toggleColorMode: () => void
}

const defaultState: ColorModeContextType = {
  mode: 'light', // default mode
  toggleColorMode: () => {},
}

export const ColorModeContext =
  createContext<ColorModeContextType>(defaultState)

export const ColorModeProvider = ({ children }: { children: ReactNode }) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light')

  useEffect(() => {
    // Retrieve mode from localStorage or default to 'light'
    const savedMode = localStorage.getItem('colorMode')
    if (savedMode === 'dark' || savedMode === 'light') {
      setMode(savedMode)
    }
  }, [])

  useEffect(() => {
    // Persist mode to localStorage
    localStorage.setItem('colorMode', mode)
  }, [mode])

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
  }

  return (
    <ColorModeContext.Provider value={{ mode, toggleColorMode }}>
      {children}
    </ColorModeContext.Provider>
  )
}
